import { createChatBotMessage } from 'react-chatbot-kit';
import InputEmail from './inputEmailWidget';

const config = {
  initialMessages: [createChatBotMessage(`Hi! I'm Jigma`,{
    widget: 'inputEmail',
  })],
  widgets: [
    {
      widgetName: 'inputEmail',
      widgetFunc: (props) => <InputEmail {...props} />,
    },
  ],
};

export default config;