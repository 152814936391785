import React from 'react';
import './header.css';

const ChatbotHeader = ({ onMinimize, onClose, title, headerColor, textColor}) => {
  return (
    <div className="chatbot-header" style={{backgroundColor: headerColor, height:'60px' }}>
  <div className="chatbot-title-container">
  <div className="bot-avatar" style={{ width: '40px', height: '40px', marginBottom:'10px', marginRight:'10px', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <svg width="40" height="40" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="14.0001" cy="14" r="14" fill="none"/>
  <path d="M13.3875 19.5262H12.1815C9.15747 19.5262 6.69147 17.0602 6.69147 14.0362V9.69824H8.83347V14.0542C8.83347 15.9082 10.3275 17.4022 12.1815 17.4022H13.3875V19.5262Z" fill={headerColor}/>
  <path d="M19.1652 9.69922V14.0552C19.1652 15.9092 17.6712 17.4032 15.8172 17.4032H15.3492L13.3692 19.5092V22.4972L13.4412 22.5512L16.2852 19.5092C19.0932 19.2572 21.2892 16.8992 21.2892 14.0552V9.69922H19.1652Z" fill={headerColor}/>
  <path d="M19.2023 9.73458C16.3403 6.87258 11.6963 6.87258 8.83433 9.73458L7.32233 8.22258C11.0123 4.53258 17.0243 4.53258 20.7143 8.22258L19.2023 9.73458Z" fill={headerColor}/>
  <path d="M16.7712 10.9941H11.3172C10.4712 10.9941 9.78717 11.6781 9.78717 12.5241V13.1181C9.78717 13.9641 10.4712 14.6481 11.3172 14.6481H16.7892C17.6352 14.6481 18.3192 13.9641 18.3192 13.1181V12.5241C18.3012 11.6961 17.6172 10.9941 16.7712 10.9941ZM11.9292 13.6221C11.4972 13.6221 11.1372 13.2801 11.1372 12.8301C11.1372 12.3801 11.4792 12.0381 11.9292 12.0381C12.3792 12.0381 12.7212 12.3801 12.7212 12.8301C12.7212 13.2801 12.3612 13.6221 11.9292 13.6221ZM16.0152 13.6221C15.5832 13.6221 15.2232 13.2801 15.2232 12.8301C15.2232 12.3801 15.5652 12.0381 16.0152 12.0381C16.4652 12.0381 16.8072 12.3801 16.8072 12.8301C16.8072 13.2801 16.4472 13.6221 16.0152 13.6221Z" fill={headerColor}/>
  
  </svg>
    </div>
    <div>
      <h3 className="chatbot-title" style={{color:textColor}}>{title}</h3>
      <p className="chatbot-status" style={{color:textColor}}>Online</p>
    </div>
  </div>
  <div className="chatbot-buttons">
    <button className="minimize-button"  onClick={() => { 
              onMinimize(); 
              console.log("La funcion de minimize es: ",typeof window.minimizeContainerSize);
              if (typeof window.minimizeContainerSize === 'function') {
                window.minimizeContainerSize();  // Assuming this function might be globally accessible
              }
            }}  style={{backgroundColor: headerColor,  marginBottom:'15px' }}>
    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.75006 9.75L14.2501 14.25M14.2501 9.75L9.75006 14.25M21.0001 12C21.0001 13.1819 20.7673 14.3522 20.315 15.4442C19.8627 16.5361 19.1997 17.5282 18.364 18.364C17.5283 19.1997 16.5361 19.8626 15.4442 20.3149C14.3523 20.7672 13.182 21 12.0001 21C10.8182 21 9.64784 20.7672 8.55591 20.3149C7.46398 19.8626 6.47183 19.1997 5.6361 18.364C4.80037 17.5282 4.13744 16.5361 3.68515 15.4442C3.23285 14.3522 3.00006 13.1819 3.00006 12C3.00006 9.61305 3.94827 7.32387 5.6361 5.63604C7.32393 3.94821 9.61311 3 12.0001 3C14.387 3 16.6762 3.94821 18.364 5.63604C20.0518 7.32387 21.0001 9.61305 21.0001 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </button>
  </div>
</div>




  );
};
export default ChatbotHeader;
