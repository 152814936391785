import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ChatbotContainer from './App';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/sw.js').then(function(registration) {
      console.log('Service worker registered:', registration);
    }, function(error) {
      console.log('Service worker registration failed:', error);
    });
  });
}



window.initChatbot = function (modelId) {
  async function setModelId(){
    await sessionStorage.setItem("modelId",modelId);
    await sessionStorage.setItem(`modelId-${modelId}`,modelId);
  }
  setModelId();
  async function setUserId(){
    await sessionStorage.setItem("userId","null")
  }
  if (sessionStorage.getItem("userId") === null) {
    setUserId();
  }
  const chatbotContainerDiv = document.createElement('div');
  chatbotContainerDiv.id = 'chatbot-container';
  document.body.appendChild(chatbotContainerDiv);
  chatbotContainerDiv.style.position = 'fixed';
  chatbotContainerDiv.style.right = '0';
  chatbotContainerDiv.style.bottom = '0';
  chatbotContainerDiv.style.zIndex = '215360';
  const root = ReactDOM.createRoot(document.getElementById('chatbot-container'));
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ChatbotContainer />
      </BrowserRouter>
    </React.StrictMode>
  );
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
