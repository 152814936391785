import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from '../src/bot/config.js';
import MessageParser from '../src/bot/MessageParser.jsx';
import ActionProvider from '../src/bot/ActionProvider.jsx';
import { createChatBotMessage } from 'react-chatbot-kit';
import './index.css'; //// Embbed
//import './index_qr.css';  //// Standalone
import InputEmail from '../src/bot/inputEmailWidget.js';
import ChatbotHeader from './bot/header.js';
import { addChatToUser,deleteModel, updateModelLink, getModels, removeLastChatOfUser, getExportChats, addChatMessageToExportChat, addChatToExportChat, validateUserKey, getUserCredits, discountCredits} from './APIFirebase';
import { set } from 'react-hook-form';
import FlightBotAvatar  from './bot/botAvatarIcon.js';
import BotChatMessage from './bot/customMessage.js';

function Loading() {
  return (
    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
  );
}

const ChatbotContainer = ({modelId,welcomeMessage2}) => {
  //const IDmodel = "1Pmv5Cbu1sQ5EF0gaSPstHIRr4r1-6" //version local
  /*
  useEffect(() => {
    async function fetch_(){
      const searchParams = await new URLSearchParams(window.location.search);
      let URLmodelId = await searchParams.get('modelId');
      await sessionStorage.setItem(`modelId`,URLmodelId);
    }
    fetch_()
  },[]);          //// Carga de modelId para standalone
  */

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (sessionStorage.getItem("chat_messages") !== null) {
        sessionStorage.removeItem("chat_messages");
      }
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  async function setUserId2(){
    await sessionStorage.setItem("userId","null")
  }
  var email = sessionStorage.getItem("userId")
  if(email === "null"){
    setUserId2();
  }
  
  async function setModelId2(){
    await localStorage.setItem(`modelId`,"IOVVQH9BWzNUFEna5w4HXN2pMVq2-26");
    //await localStorage.setItem(`modelId`,modelId);
    //await localStorage.setItem(`modelId-${IDmodel}`,IDmodel)
  }
  //setModelId2() //version local
    
  const [isOpen, setIsOpen] = useState(false); //// Embbed
  //const [isOpen, setIsOpen] = useState(true);  //// Standalone
  const [config, setConfig] = useState(null);
  const [modelId2,setModelId] = useState(null);
  const [uuid, setUUID] = useState(null);
  const [credits, setCredits] = useState(null);
  const [chatActive,_chatActive] = useState(0);
  const [personality, setPersonality] = useState(null);
  const [welcomeMessage, setWelcomeMessage] = useState("Hello and welcome! I am your friendly AI assistant. How can I help you today?");
  const [chatTitle, setChatTitle] = useState("Chatbot");
  const [headerColor1,setHeaderColor1] = useState("#376B7E");
  const [headerColor2,setHeaderColor2] = useState("#5ccc9d");
  const [contextUrl, setContextUrl] = useState(null);
  const [buttonText, setButtonText] = useState("Chat with me");
  const [userId, setUserId] = useState(null);
  const [ready, setReady] = useState(false);
  const [chatbotReady, setChatbotReady] = useState(false);
  const toggleChatbot = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen); //// Comentar para version standalone
  };


  //////////////////////////////////
  async function getModelId(){
    var _modelId = await sessionStorage.getItem("modelId");
    if(_modelId){
        const export_chats = await getExportChats(_modelId)
        var _headerColor1 = export_chats.headerColor1
        setHeaderColor1(_headerColor1)
        var _headerColor2 = export_chats.headerColor2
        setHeaderColor2(_headerColor2)
        var _chatTitle = export_chats.chatTitle
        setChatTitle(_chatTitle)
        var _personality = export_chats.personality
        setPersonality(_personality)
        var _buttonText = export_chats.buttonText
        setButtonText(_buttonText)
        var _welcomeMessage = export_chats.welcomeMessage
        setWelcomeMessage(_welcomeMessage)
        var _contextUrl = export_chats.contextUrl
        setContextUrl(_contextUrl)
        setModelId(_modelId)
        const newArray = _modelId.split('-')
        var _uuid = newArray[0]
        //var _uuid = await localStorage.getItem("uid")
        //console.log("uuid",_uuid)
        setUUID(_uuid)
        //const _models = await getModels(uuid)
        //setModels(_models)
        //models = _models
        const coins = await getUserCredits(_uuid);
        setCredits(coins);
        setReady(true);
        
    }

}

function isLightColor(color) {
  const rgb = color.startsWith('#') ? parseInt(color.slice(1), 16) : parseInt(color, 16);
  const r = (rgb >> 16) & 255;
  const g = (rgb >> 8) & 255;
  const b = rgb & 255;

  // Calculate the perceived luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  console.log("la luminance es: ",luminance);
  return luminance > 0.6; // Return true if light, false if dark
}

const textColor = isLightColor(headerColor1) ? 'black' : 'white';
if(modelId2==null && contextUrl==null && personality==null) {
  async function fetch() {
    const searchParams = await new URLSearchParams(window.location.search);
    let URLmodelId = await searchParams.get('modelId');
    await sessionStorage.setItem('modelId', URLmodelId);
  }

  //fetch();

  // Wait for 1 second (1000 milliseconds) before running getModelId
  setTimeout(() => {
    getModelId();
  }, 1000);
}

///////////////////////////////////////////////////////
  const loadMessages = () => {
    const chat_history = sessionStorage.getItem('chat_messages');
    console.log("el chat history es: ", chat_history);
    if(chat_history !== undefined){
      console.log("entro al if");
      const messages = JSON.parse(sessionStorage.getItem('chat_messages'));
      return messages;
    } 
  };
  const validateInput = (message) => {
    var _userId =  sessionStorage.getItem("userId")
    if(message==""){
      return false;
    }
    if(_userId == "null"){
      return false;
    }
    return true;
  }


  useEffect(() => {
    const divElement = document.querySelector('.entire-chatbot-container');
    if (divElement) {
      divElement.classList.toggle('active', isOpen);
      console.log("toggle class");
    }
  }, [isOpen]);

  useEffect(() => {
    const delay = 3000;
  
    const timer = setTimeout(() => {
      setChatbotReady(true);
    }, delay);
  
    return () => clearTimeout(timer);
  
  }, [config]);
  
  useEffect(() => {
    async function defineConfig() {

      // Define the config based on fetched variables
      const _config = {
        initialMessages: [
          createChatBotMessage(welcomeMessage),
          createChatBotMessage('Please enter your email to begin.', {
            widget: 'inputEmail',
          }),
        ],
        
        customComponents: {
          // Replaces the default header
          header: (props) => <ChatbotHeader {...props} onMinimize={toggleChatbot} title={chatTitle} headerColor={headerColor1} textColor={textColor} />,
          botAvatar: (props) => <FlightBotAvatar  {...props} avatarColor={headerColor1} />,
          botChatMessage: (props) => <BotChatMessage {...props} containerColor={headerColor1} />,
        },
        customStyles: {
          // Overrides the chatbot message styles
          botMessageBox: {
            backgroundColor: headerColor1,
          },
          // Overrides the chat button styles
          chatButton: {
            backgroundColor: headerColor2,
          },
        },
        widgets: [
          {
            widgetName: 'inputEmail',
            widgetFunc: (props) => <InputEmail {...props} />,
          },
        ],
      };
      // Update the state with the new config
      setConfig(_config);
    }

    defineConfig();
  }, [ready]);

  return(
    <div>
      {config ? (
        chatbotReady ? (
          <div>
          <div className='entire-chatbot-container'>
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            validator={validateInput}
            messageHistory={loadMessages()}
          />
          <div className='chatbot-footer'>
            <span className='footer-text'>
              Powered by <a href='https://app.jigma.io' target="_blank" rel="noopener noreferrer"><b className='jigma-hyperlink'>Jigma</b></a>
            </span>
          </div>
                       
          </div>
          <button 
            className={`rounded-button ${!isOpen && 'active'}`} 
            onClick={() => { 
              toggleChatbot(); 
              console.log("La funcion de minimize es: ",typeof window.maximizeContainerSize);
              if (typeof window.maximizeContainerSize === 'function') {
                window.maximizeContainerSize();  // Assuming this function might be globally accessible
              }
            }} 
            style={{backgroundColor:headerColor1}}
          >
            <span className='chat-with-us-text' style={{color:textColor}}>{buttonText}</span>
        </button> 
        </div>
        ):(
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}></div>
        )
        
      ) : (
        <div>Loading...</div>
      )}
    </div>
  )
};



export default ChatbotContainer;