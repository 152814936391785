import React, { useEffect, useState } from 'react';
import { addChatToExportChat } from '../APIFirebase';
import { createChatBotMessage } from 'react-chatbot-kit';
import './inputEmail.css';
import { db } from '../firebase';
import { doc, onSnapshot } from "firebase/firestore";

const InputEmail = ({ setState, children }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // New state variable
  const [logged, setlogged] = useState(false);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    /*console.log(isFirstRun.current);
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }*/
    var _modelId = sessionStorage.getItem("modelId");
    const docRef = doc(db, "export_chats", _modelId);
    const userEmail = sessionStorage.getItem("userId");
  
    // Listen for real-time updates
    const unsubscribe = onSnapshot(docRef,(docSnapshot) => {
  
      if (docSnapshot.exists) {
  
        const allChats = docSnapshot.data().chats; // Assuming 'chats' is the field
        const userChat = allChats.find(chat => chat.uuid === userEmail);
  
        if (userChat) {
          console.log("User chat found:", userChat.chat[5]);
          
          const lastMessage = userChat.chat.slice(-1)[0].message;
          const userRole = userChat.chat.slice(-1)[0].role;
          if (userChat.status === "HUMAN" && userRole === "owner"){
            const botMessage = createChatBotMessage(lastMessage);
            setState((prev) => {
              const updatedMessages = [...prev.messages];
              updatedMessages.push(botMessage); // Add the new message
              //console.log("Messages:", updatedMessages); // Log the messages
              const messagesString = JSON.stringify(updatedMessages);
              sessionStorage.setItem('chat_messages', JSON.stringify(updatedMessages));
              //console.log(messagesString);
              return {
                ...prev,
                messages: updatedMessages,
              };
            });
          }
          
        } else {
          console.log("No user chat found");
        }
      } else {
        console.log("No such document");
      }
    }, (error) => {
      console.log("Snapshot error:", error);
    });
  
    // Cleanup
    return () => {
      console.log("Unsubscribing snapshot listener");
      unsubscribe();
    };
  }, [isSubmitted]);

  useEffect(() => {
    const emailStored = sessionStorage.getItem('userId');
    if(emailStored){
      if (emailStored !== 'null') {
      setlogged(true);
    }
    }
    
  }, []);
  useEffect(() => {
    const emailStored = sessionStorage.getItem('userId');
    if(emailStored){
      if (emailStored !== 'null') {
      setlogged(true);
    }
    }
    
  }, [isSubmitted]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check if the email is not empty and is a valid email format
    if (email.trim() === '') {
      setErrorMessage('Please enter your email.');
    } else if (!isValidEmail(email)) {
      setErrorMessage('Please enter a valid email.');
    } else {
      setErrorMessage(''); // Clear any existing error message
      

      const _modelId = await sessionStorage.getItem('modelId');
      await sessionStorage.setItem('userId', email);
      await addChatToExportChat(email, _modelId);
      setIsSubmitted(true); // Mark email as submitted

      const botMessage = createChatBotMessage('Thank you. We are all set. How can I help you?');
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="email-card">
      {!logged ? (
        <form onSubmit={handleSubmit} className='form-email-input'>
        <label htmlFor="email-input">Insert your email:</label>
        <input
          type="email"
          id="email-input"
          placeholder="example@example.com"
          value={email}
          onChange={handleInputChange}
          disabled={isSubmitted} // Disable input after submission
        />
        <button type="submit" disabled={isSubmitted}>
          Submit
        </button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
      ):(
        <label htmlFor="email-input">Thank you!</label>
      )}
      
    </div>
  );
};

export default InputEmail;
