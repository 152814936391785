import React, { useEffect, useState } from 'react';
import ConditionallyRender from 'react-conditionally-render';
import ReactMarkdown from 'react-markdown';
import './customMessage.css';

const BotChatMessage = ({ message, loader ,containerColor}) => {
  const [loading, setLoading] = useState(false);

  // You can define loading logic here. For now, let's use an effect that simulates asynchronous loading.
  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 750); // Simulates an asynchronous operation.
  }, []);

  return (
    <div className="react-chatbot-kit-chat-bot-message" style={{backgroundColor: '#F2F6F9'}}>
      <ConditionallyRender
        condition={loading}
        show={loader}
        elseShow={<ReactMarkdown className='custom-message-markdown' linkTarget="_blank">{message}</ReactMarkdown>}
      />
          <div
            className="react-chatbot-kit-chat-bot-message-arrow"
            style={{borderRightColor: '#F2F6F9'}}
          ></div>
        
      
    </div>
  );
}

export default BotChatMessage;
