import axios from 'axios';
import { API_URL } from './constants';

const headers = {
    'Content-Type': 'application/json;',
    'X-Requested-With': 'XMLHttpRequest'
  };

export const createModel = (link_url,uuid,title,files) => {
  // console.log("link ",link_url)
  // console.log("uuid ",uuid)
  // console.log("title ",title)
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files',file);
  });
  return new Promise((resolve, reject) => {
    axios
      .post(                           
        //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
        `${API_URL}/upsert-file?project_name=${title}&uuid=${uuid}&user_name=${uuid.slice(0, -2)}`,
        formData,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest'},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (createModel): ');
        // console.log(error.message);
        reject(error);
      });
  });
};


export const getAnswer = (context_url,personality,question,body,model) => {
  // console.log("context ",context_url)
  // console.log("question ",question)
  //console.log("api MODEL",model)
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/sendQuestionFirebase?context_url=${context_url}&question=${question}&personality=${personality}&model=${model}`,
        body,
        {
            headers: {...headers},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (getAnswer): ');
        console.log(error.message);
        //reject(error);
        resolve("API ERROR")
      });
  });
};
export const checkStatus = (uuid,title) => {
  var context_url = "context/"+uuid.slice(0, -2)+"/data_context_"+title+".csv"
  console.log(context_url)
  // console.log("UUID MODEL ",uuid)
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${API_URL}/getModelStateLinkFirebase?context_url=${context_url}`,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {...headers},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (checkStatus): ');
        console.log(error.message);
        reject(error.message);
      });
  });
};

export const getDeviceToken = (uuid) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/getDeviceTokenFirebase?uid=${uuid}`,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {...headers},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (checkStatus): ');
        console.log(error.message);
        reject(error.message);
      });
  });
};

export const sendNotification = (device_token,export_chat_id,email,title,body,chat_title) => {
  console.log("Ejecuta notificacion", chat_title);
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/sendNotificationJigmaChatManager?device_token=${device_token}&export_chat_id=${export_chat_id}&email=${email}&title=${title}&body=${body}&chat_title=${chat_title}`,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {...headers},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (checkStatus): ');
        console.log(error.message);
        reject(error.message);
      });
  });
};