import { ref } from "firebase/database";
import { doc, query, collection, getDocs, where , updateDoc, getDoc, setDoc, addDoc, onSnapshot } from "firebase/firestore";
import { db } from './firebase';
import { getStorage,  ref as sRef,uploadString,getDownloadURL,uploadBytesResumable } from "firebase/storage";

export const addModelToUser = async (title,description,userId,personality) => {
    try {
        const docRef = doc(db, "customers", userId);
        const docSnap = await getDoc(docRef);
        const _createdModels = docSnap.data().createdModels 
        const models = docSnap.data().models;
        const uuid = userId+"-"+(_createdModels+1).toString();
        //const uuid = userId+"-"+models.length;
        const _models = models;
        _models.push({
            title,
            description,
            personality: personality,
            uuid,
            link: null,
            chat: []
          });
        await updateDoc(docRef, {
          createdModels: _createdModels + 1,
          models: _models
        });
        
        return uuid;
    } catch (error) {
        console.log("ERROR (addModelToUser) ",error)
    }

}

export const addChatToUser = async (text,userId,models,chatActive) => {
    try {
        const docRef = doc(db, "customers", userId);
        const _models = models;
        _models[chatActive].chat.push(text);
        await updateDoc(docRef, {
          models: _models
        });
    } catch (error) {
        console.log("ERROR (addChatToUser) ",error)
    }

}


export const removeLastChatOfUser = async (userId,models,chatActive) => {
  try {
      const docRef = doc(db, "customers", userId);
      const _models = models;
      _models[chatActive].chat.pop();
      await updateDoc(docRef, {
        models: _models
      });
  } catch (error) {
      console.log("ERROR (addChatToUser) ",error)
  }

}

export const updateModel = async (userId,) => {
    try {

      // const storage = getStorage();
      // const storageRef = sRef(storage, file_name);
      // Raw string is the default if no format is provided
      // const message = data;
      // await uploadString(storageRef, message)

      // const url = await getDownloadURL(storageRef)
      // console.log("URL  ",url)
      const docRef = doc(db, "customers", userId);
      const docSnap = await getDoc(docRef);
      const models = docSnap.data().models;
      const _models = models;
      // console.log(_models[_models.length-1])
      _models[_models.length-1].link = null;

        
        await updateDoc(docRef, {
          models: _models
        });


        
    } catch (error) {
        console.log("ERROR(updateModel) ",error)
    }

}


export const updateModelLink = async (userId,models,link) => {
    try {

    const docRef = doc(db, "customers", userId);
    const _models = models;
    _models[models.length-1].link = link
      // console.log("LINK  ",link)
    await updateDoc(docRef, {
      models: _models,
    });

        
    } catch (error) {
        console.log("ERROR(updateModelLink) ",error)
    }

}

export const uploadfile = async (file_name,file) => {

  return new Promise((resolve, reject) => {
    const storage = getStorage();
      const storageRef = sRef(storage,'data/Mauricio/'+ file_name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // console.log(progress);
      },
      (error) => {
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log(downloadURL)
          resolve( downloadURL);
        });
      }
    );
  })
   
      
  
}
export const getModels = async (userId) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then((doc)=> {
        resolve(doc.data().models)
      }).catch((e) => {
        reject(e)
      });
  
  })
   
      
  
}
export const deleteModel = async (userId,models,user) => {
// console.log({
//   ...user,
//   models: models,
// })
  try {
    const docRef = doc(db, "customers", userId);
    await updateDoc(docRef, {
      ...user,
      models,
      hasUnfinishModel: false
    });
  } catch (error) {
      console.log("ERROR (deleteModel) ",error)
  }
   
      
  
}

export const updateCustomData = async (userId,models,user,customData,chatActive) => {
  // console.log({
  //   ...user,
  //   models: models,
  // })
  // console.log("userId",userId)
  // console.log("models",models)
  // console.log("user",user)
  // console.log("customData",customData)
  // console.log("chatActive",chatActive)
    try {

      const docRef = doc(db, "customers", userId);
      models[chatActive].customData = customData
      await updateDoc(docRef, {
        ...user,
        models,
      });
    } catch (error) {
        console.log("ERROR (updateCustomData) ",error)
    }
     
        
    
  }
  

  export const getCustomData = (userId,chatActive) => {
    return new Promise((resolve, reject) => {

      const docRef = doc(db, "customers", userId);
        getDoc(docRef).then((doc)=> {
          resolve(doc.data().models[chatActive].customData)
        }).catch((e) => {
          reject(e)
        });
    
    })
  }


  export async function  getProductByid(id){
    const cRef = collection(db,"products");
    const dRef = doc(cRef,id)
    const snapDoc = await getDoc(dRef)
    const product = snapDoc.data();
    return product;

  }

  export async function createCheckoutSession(uid,priceId,credits){
    const cRef = collection(db,"customers/"+uid+"/checkout_sessions");
    const {id} = await addDoc(cRef,{
      mode:"payment",
      success_url: window.location.origin+"?session_id={CHECKOUT_SESSION_ID}",
      cancel_url:  window.location.origin+"/payments",
      credits: credits,
      added_credits: false,
      line_items:[
        {quantity: 1,price: priceId}

      ]
    })

    const cancel = onSnapshot(doc(db,"customers/"+uid+"/checkout_sessions/"+id),
    (sn) => {
      const url = sn.data().url;
      if (url ){
        cancel();
        window.location.href = url;
      }
    }
    
    
    )

  }

  export  async function getActiveProducts () {
    const cRef = collection(db,"products")
    const filter = query(cRef,where('active',"==",true))
    const snaps = await getDocs(filter);
    const products = [];

    for await (const snap of snaps.docs){
        const product = snap.data();
        product.id = snap.id;
        const sPrice = await getDocs(collection(snap.ref,"prices"))
        product.price = sPrice.docs[0].data()
        product.priceId = sPrice.docs[0].id
        product.credits = sPrice.docs[0].data().transform_quantity.divide_by
        products.push(product)
    }

    return products;

}

//USER CREDITS MANIPULATION///////////////////////////////////////////////////////////
export const addCredits = async (userId,buyedCredits) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().credits)
        if(doc.data().credits<0){
          var _credits = buyedCredits
        }else{
          var _credits = doc.data().credits + buyedCredits
        }
        
        //console.log(_credits)
        await updateDoc(docRef, {
          credits: _credits
        });
        
      }).catch((e) => {
        reject(e)
      });
  
  })
   
      
  
}


export const discountCredits = async (userId,modelName) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().credits)
        if(modelName == "GPT3.5"){
          var _credits = doc.data().credits - 1
        }
        if(modelName == "GPT4"){
          var _credits = doc.data().credits - 10
        }
        
        //console.log(_credits)
        await updateDoc(docRef, {
          credits: _credits
        });
        
      }).catch((e) => {
        reject(e)
      });
  
  })
       
  
}


export const getUserCredits = async (userId) => {

  return new Promise((resolve, reject) => {
    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().credits)
        console.log(doc.data().credits)
      }).catch((e) => {
        reject(e)
      });
  
  })
   
      
  
}

//USER CREDITS MANIPULATION///////////////////////////////////////////////////////////

export  async function updateCheckoutSession (userId,sessionId) {
  console.log(userId,sessionId)
  const cRef = collection(db,"customers/"+userId+"/checkout_sessions");
  const filter = query(
    cRef,
    where('sessionId', '==', sessionId),
    where('added_credits', '==', false)
  );
  const snap = await getDocs(filter);
  var checkout_session
  

  if(snap.docs[0].data()){
    checkout_session = snap.docs[0].data()
    console.log(checkout_session)
    const credits = checkout_session.credits
    await addCredits(userId,credits)
    await addModels(userId,10)
    const querySnapshot = await getDocs(
      query(
        collection(db, "customers", userId, "checkout_sessions"),
        where("sessionId", "==", sessionId)
      )
    );
    
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      try {
        await updateDoc(docRef, { added_credits: true });
        console.log('Checkout session updated successfully.');
      } catch (error) {
        console.error('Error updating checkout session:', error);
      }
    } else {
      console.log('No matching checkout session found.');
    }

  }

}

//USER MODELS CREATION REMAINING MANIPULARTION////////////////////////////////////////

export const discountModels = async (userId) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().remaining_models)
        var remaining_models = doc.data().remaining_models
        var _remaining_models = remaining_models - 1
        //console.log(_credits)
        await updateDoc(docRef, {
          remaining_models: _remaining_models
        });
        
      }).catch((e) => {
        reject(e)
      });
  
  })
       
  
}

export const addModels = async (userId,buyedModels) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().remaining_models)
        var remaining_models = doc.data().remaining_models
        var _remaining_models = remaining_models + buyedModels
        //console.log(_credits)
        await updateDoc(docRef, {
          remaining_models: _remaining_models
        });
        
      }).catch((e) => {
        reject(e)
      });
  
  })
       
  
}

export const getModelsRemaining = async (userId) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().remaining_models)
        console.log(doc.data().remaining_models)
      }).catch((e) => {
        reject(e)
      });
  
  })
   
      
  
}
//USER MODELS CREATION REMAINING MANIPULARTION////////////////////////////////////////

//EXPORT CHATS//////////////////////////////////////////////////////////////////////////////////////
export const addExportChat = async (modelId,logoImage,chatTitle,headerColor1,headerColor2) => {
  try {
        await setDoc(doc(db,"export_chats/"+modelId), {
          logoImage,
          chatTitle,
          headerColor1,
          headerColor2,
          chats: []
        });
        
        return modelId;
    } catch (error) {
        console.log("ERROR (addExportChat) ",error)
    }

}

export const addChatToExportChat = async (userId,modelId) => {
  try {
      const docRef = doc(db, "export_chats/"+modelId);
      const docSnap = await getDoc(docRef);
      const chats = docSnap.data().chats;
      //const uuid = userId+"-"+models.length;
      var found = false
      for (var chat in chats) {
        if (chats[chat].uuid === userId) {
          found = true
        }
      }
      if(found==false){
        const _chats = chats;
        _chats.push({
          uuid: userId,
          chat: [],
          status: "AI"
        });
        await updateDoc(docRef, {
        chats: _chats
        });
      }
      
      
      return {
        userId,
        chat: []
      };
  } catch (error) {
      console.log("ERROR (addChatToExportChat) ",error)
  }

}

export const addChatMessageToExportChat = async (text, userId, modelId) => {
  try {
    //let userChatId;
    //console.log(text,userId,modelId)
    const docRef = doc(db, "export_chats/" +modelId);
    const docSnap = await getDoc(docRef);
    const chats = docSnap.data().chats;
    //const _chats = [...chats]; // create a copy of the array

    for (var chat in chats) {
      if (chats[chat].uuid === userId) {
        chats[chat].chat.push(text);
        await updateDoc(docRef, {
          chats: chats
        });
        //userChatId = chat.id;
        break;
      }
    }

  } catch (error) {
    console.log("ERROR (addChatMessageToExportChat)", error);
  }
};




export const removeChatMessageFromExportChat = async (modelId, userId) => {
  try {
    let userChatId;
    const docRef = doc(db, "export_chats/" + modelId);
    const docSnap = await getDoc(docRef);
    const chats = docSnap.data().chats;
    const _chats = [...chats]; // create a copy of the array

    for (const chat of _chats) {
      if (chat.userId === userId) {
        userChatId = chat.id;
        break;
      }
    }

    if (userChatId !== undefined) {
      _chats[userChatId].chat.pop();
      await updateDoc(docRef, {
        chats: _chats
      });
    } else {
      console.log("User chat not found");
    }
  } catch (error) {
    console.log("ERROR (removeChatFromExportChat)", error);
  }
};



export const getExportChats = async (modelId) => {
  try {
    

    let userChatId;
    const docRef = doc(db, "export_chats/"+ modelId);
    const docSnap = await getDoc(docRef);
    const export_chat = docSnap.data();
    

    return export_chat;
  } catch (error) {
    console.log("ERROR (getChatFromExportChats)", error);
  }
};

//EXPORT CHATS//////////////////////////////////////////////////////////////////////////////////////

export const validateUserKey = async (userId, key) => {
  try {
    const docRef = doc(db, "customers", userId);
    const docSnap = await getDoc(docRef);
    const _accessKey = docSnap.data().accessToken;
    const newArray = _accessKey.split('.');
    const _accessKeyFirstPart = newArray[0];
    const valid = _accessKeyFirstPart === key;
    console.log("yes", _accessKeyFirstPart, key);
    return valid;
  } catch (error) {
    throw error;
  }
};


export const addSessionToExportChat = async (modelId, userSession) => {
  try {
    //let userChatId;
    //console.log(text,userId,modelId)
    const docRef = doc(db, "export_chats/" +modelId);
    const docSnap = await getDoc(docRef);
    const usersSessions = docSnap.data().usersSessions;
    const _usersSessions = usersSessions
    _usersSessions.push(userSession)
    //const _chats = [...chats]; // create a copy of the array

    await updateDoc(docRef, {
      usersSessions: _usersSessions
    });

  } catch (error) {
    console.log("ERROR (addSessionToExportChat)", error);
  }
};