import React from 'react';
import { getDate, useAuth } from '../utils';
import {  useEffect, useState, useRef } from 'react';
import {  getAnswer, sendNotification, getDeviceToken } from '../API';
import InputEmail from '../bot/inputEmailWidget.js';
import {  getExportChats, addChatMessageToExportChat, getUserCredits, discountCredits, addSessionToExportChat} from '../APIFirebase';
import { db } from '../firebase';
import { doc, onSnapshot } from "firebase/firestore";

const ActionProvider = ({ createChatBotMessage, setState, children } ) => {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [modelId,setModelId] = useState(null)
    const [uuid, setUUID] = useState(null);
    const [models, setModels] = useState([]);
    const [credits, setCredits] = useState(null);
    const [chatActive,_chatActive] = useState(0);
    const [userChat, setUserChat] = useState([]);
    const [personality, setPersonality] = useState(null);
    const [welcomeMessage, setWelcomeMessage] = useState(null);
    const [chatTitle, setchatTitle] = useState("");
    const [contextUrl, setContextUrl] = useState(null);
    const [userId, setUserId] = useState(null);
    const [thinking, setThinking] = useState(false);
    const [chatStatus, setChatStatus] = useState("AI");
    const isFirstRun = useRef(true);
    const [userLanguage, setUserLanguage] = useState(null);
    const [screenWidth, setScreenWidth] = useState(null);
    const [screenHeight , setScreenHeight] = useState(null);
    const [browserName , setBrowserName] = useState(null);
    const [deviceType, setDeviceType] = useState(null);
    //var modelOrder
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 1000);
  
      const userLanguage = navigator.language || navigator.userLanguage;
      setUserLanguage(userLanguage);
      //console.log(`User Language: ${userLanguage}`);
      // Get user agent
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      setScreenWidth(screenWidth);
      setScreenHeight(screenHeight);
      //console.log(`Screen width: ${screenWidth}, Screen height: ${screenHeight}`);

      const userAgent = navigator.userAgent;
      //console.log(`User Agent: ${userAgent}`);
      // Get browser information
      const browserName = (function() {
        const agent = userAgent.toLowerCase();
        if (agent.includes('chrome')) return 'Chrome';
        if (agent.includes('safari')) return 'Safari';
        if (agent.includes('firefox')) return 'Firefox';
        if (agent.includes('edge')) return 'Edge';
        if (agent.includes('opera') || agent.includes('opr')) return 'Opera';
        if (agent.includes('msie') || agent.includes('trident')) return 'Internet Explorer';
        return 'Unknown';
      })();
      setBrowserName(browserName);
      //console.log(`Browser: ${browserName}`);
      // Get device information
      const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
      const deviceType = isMobile ? 'Mobile' : 'Desktop';
      setDeviceType(deviceType);
      //console.log(`Device Type: ${deviceType}`);
    }, []);

    useEffect(() => {
      const buttonElement = document.querySelector('.react-chatbot-kit-chat-btn-send');
      buttonElement.disabled = thinking;  
    }, [thinking])
    
    async function getModelId(){
        var _modelId = await sessionStorage.getItem("modelId");
        const userEmail = sessionStorage.getItem("userId");
        if(_modelId){
            const export_chats = await getExportChats(_modelId)
            var _personality = export_chats.personality
            setPersonality(_personality)
            var _welcomeMessage = export_chats.welcomeMessage
            setWelcomeMessage(_welcomeMessage)
            var _contextUrl = export_chats.contextUrl
            var _chatTitle = export_chats.chatTitle
            setchatTitle(_chatTitle);
            setContextUrl(_contextUrl);
            setModelId(_modelId);
            setUserId(userEmail);
            const newArray = _modelId.split('-')
            var _uuid = newArray[0]
            //var _uuid = await localStorage.getItem("uid")
            //console.log("uuid",_uuid)
            setUUID(_uuid)
            //const _models = await getModels(uuid)
            //setModels(_models)
            //models = _models
            const coins = await getUserCredits(_uuid);
            setCredits(coins);
            
            
        }

    }
    if(modelId==null&&contextUrl==null&&personality==null){
        getModelId() 
    }

    useEffect(() => {
      var _modelId = sessionStorage.getItem("modelId");
      const docRef = doc(db, "export_chats", _modelId);
      const userEmail = sessionStorage.getItem("userId");
    
      // Listen for real-time updates
      const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
        if (docSnapshot.exists) {
          const allChats = docSnapshot.data().chats;
          const userChat = allChats.find(chat => chat.uuid === userEmail);
    
          if (userChat) {
            // Update the state with the new status
            setChatStatus(userChat.status);
          }
        }
      }, (error) => {
        console.log("Snapshot error:", error);
      });
    
      // Cleanup
      return () => {
        unsubscribe();
      };
    }, [thinking]); 
    
    
    const handleJigma = async (message) => {
      if(chatStatus === "AI"){
      var _userId =  await sessionStorage.getItem("userId")
      console.log("credits",credits)
      if(credits >= 1){
        if(_userId == "null"){
          botMessage = createChatBotMessage(`Please enter your email first.`,{
            widget: 'inputEmail',
          })

          setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
          }));
        }else{
          botMessage = createChatBotMessage(`I'm thinking...`);
          setState((prev) => ({
            ...prev,
            messages: [...prev.messages,botMessage],
            isLoading: true,
          }));

          var messages = sessionStorage.getItem("chat_messages");
          //console.log("MESSAGES: ",messages)
          if(messages === null){
            const formattedDate = currentDateTime.toLocaleDateString();
            const formattedTime = currentDateTime.toLocaleTimeString();
            const __userEmail = sessionStorage.getItem("userId");
            const userSession = {
              userEmail: __userEmail,
              sessionDate: formattedDate,
              sessionTime: formattedTime,
              sessionTimeZone: timeZone,
              sessionLanguage: userLanguage,
              sessionDeviceType: deviceType,
              sessionBrowser: browserName,
              sessionScreenHeight: screenHeight,
              sessionScreenWidth: screenWidth
            };
            //console.log("User Session:",userSession)
            await addSessionToExportChat(modelId,userSession)
            
          }
          
          var botMessage
          var response
          var question = message
          var modelOpenAI = "GPT3.5"
          var body = null
          setThinking(true);

          
          messages = JSON.parse(messages)
          console.log("messages",messages)
          if(messages){
            if(messages.length > 0 ){
              // Delete the first two messages
              messages.splice(0, 2);

              // Separate into questions and answers
              let _questions = [];
              let _answers = [];
              let currentMessage = "";
              for (let msg of messages) {
                if (msg.type === "user") {
                    // If there's a previous answer, add it to the answers array
                    if (currentMessage !== "") {
                        _answers.push(currentMessage);
                        currentMessage = "";
                    }
                    // Add the user question to the questions array
                    _questions.push(msg.message);
                } else if (msg.type === "bot" || msg.type === "owner") {
                    // Concatenate consecutive answers
                    currentMessage += msg.message;
                }
              }
            
              // If there's a final answer, add it to the answers array
              if (currentMessage !== "") {
                  _answers.push(currentMessage);
              }
              if(_questions.length <= 3){
                body = {"questions": _questions , "answers": _answers}
              }else{
                body = {"questions": _questions.slice(-3) , "answers": _answers.slice(-3)}
              }
            }
          }
          
          
          if (personality == null){
            var _personality = "an assistant"
            console.log("el body", body);
            response =  await getAnswer(contextUrl,_personality,question,body,modelOpenAI)
          }else{
            console.log("el body", body);
            response =  await getAnswer(contextUrl,personality,question,body,modelOpenAI)
          }
          
          if(response!="API ERROR"){
            botMessage = createChatBotMessage(response.data.answer);
            questions.push(message);
            
            // Create a Date object using the current timestamp
            const dateObject = new Date();
            // Get the timestamp in milliseconds
            const timestamp = dateObject.getTime();
            
            // Function to get the UTC offset string (e.g., UTC-3)
            function getUTCOffsetString(date) {
              const offsetInMinutes = date.getTimezoneOffset();
              const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
              const offsetMinutes = Math.abs(offsetInMinutes) % 60;
              const sign = offsetInMinutes < 0 ? '+' : '-';
              return `UTC${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
            }
            
            // Format the date as "July 25, 2023, 3:10:07 PM UTC-3"
            const formattedDate = `${dateObject.toLocaleString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })} ${getUTCOffsetString(dateObject)}`;
            
            const q = {
              role: "user",
              message: message,
              date: formattedDate,
              date_s: timestamp
            };
            
            await addChatMessageToExportChat(q, _userId, modelId);
            answers.push(response.data.answer);
            
            const dateObject1 = new Date();
            // Get the timestamp in milliseconds
            const timestamp1 = dateObject1.getTime();
            
            const formattedDate1 = `${dateObject1.toLocaleString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })} ${getUTCOffsetString(dateObject1)}`;
            
            const a = {
              role: "chatbot",
              message: response.data.answer,
              date: formattedDate1,
              date_s: timestamp1
            };
            
            await addChatMessageToExportChat(a, _userId, modelId);
            await setQuestions(questions);
            await setAnswers(answers);
            await discountCredits(uuid, modelOpenAI);
            const _coins = await getUserCredits(uuid);
            setCredits(_coins);
            console.log("credits2", _coins);
          }else{
              botMessage = createChatBotMessage("There was an error, please try again.");
          }
          
          setState((prev) => {
            const updatedMessages = [...prev.messages];
            updatedMessages.pop(); // Remove the last message
            updatedMessages.push(botMessage); // Add the new message
            //console.log("Messages:", updatedMessages); // Log the messages
            const messagesString = JSON.stringify(updatedMessages);
            sessionStorage.setItem('chat_messages', JSON.stringify(updatedMessages));
            //console.log(messagesString);
            return {
              ...prev,
              messages: updatedMessages,
            };
          });
          setThinking(false);
        }
      }else{
        botMessage = createChatBotMessage("The owner has turned off this chatbot. We apologize for the inconvenience.");
        setState((prev) => {
          const updatedMessages = [...prev.messages];
          updatedMessages.pop(); // Remove the last message
          updatedMessages.push(botMessage); // Add the new message
          //console.log("Messages:", updatedMessages); // Log the messages
          const messagesString = JSON.stringify(updatedMessages);
          sessionStorage.setItem('chat_messages', JSON.stringify(updatedMessages));
          //console.log(messagesString);
          return {
            ...prev,
            messages: updatedMessages,
          };
        });
        setThinking(false);
      }
    }else{
      var _userId =  await sessionStorage.getItem("userId");
      console.log("add user message when talking to human");
      questions.push(message);
            
      // Create a Date object using the current timestamp
      const dateObject = new Date();
      // Get the timestamp in milliseconds
      const timestamp = dateObject.getTime();
      
      // Function to get the UTC offset string (e.g., UTC-3)
      function getUTCOffsetString(date) {
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes < 0 ? '+' : '-';
        return `UTC${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
      }
      
      // Format the date as "July 25, 2023, 3:10:07 PM UTC-3"
      const formattedDate = `${dateObject.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      })} ${getUTCOffsetString(dateObject)}`;
      
      const q = {
        role: "user",
        message: message,
        date: formattedDate,
        date_s: timestamp
      };
      
      await addChatMessageToExportChat(q, _userId, modelId);
    }
    };

    const sendNotificationToApp = async (message) => {
      var botMessage = createChatBotMessage(`Got it! I will contact someone to joing the conversation and help you. If you dont receive an answer now, we will contact with you by email.`);
      const userEmail = sessionStorage.getItem("userId");
      const body = `The user ${userEmail} is waiting for assistance in the chat. Please join the conversation.`
      setState((prev) => {
        const updatedMessages = [...prev.messages];
        updatedMessages.push(botMessage); // Add the new message
        //console.log("Messages:", updatedMessages); // Log the messages
        const messagesString = JSON.stringify(updatedMessages);
        sessionStorage.setItem('chat_messages', JSON.stringify(updatedMessages));
        //console.log(messagesString);
        return {
          ...prev,
          messages: updatedMessages,
        };
      });
      const dateObject = new Date();
      // Get the timestamp in milliseconds
      const timestamp = dateObject.getTime();
      
      // Function to get the UTC offset string (e.g., UTC-3)
      function getUTCOffsetString(date) {
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes < 0 ? '+' : '-';
        return `UTC${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
      }
      
      // Format the date as "July 25, 2023, 3:10:07 PM UTC-3"
      const formattedDate = `${dateObject.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      })} ${getUTCOffsetString(dateObject)}`;
      
      const q = {
        role: "user",
        message: message,
        date: formattedDate,
        date_s: timestamp
      };
      
      await addChatMessageToExportChat(q, userEmail, modelId);
      console.log("los datos enviados send notification jigma chat manager son ",chatTitle);
      const dev_token = await getDeviceToken(uuid);
      console.log("el device token del dispositivo es: ",dev_token.data);
      const res = await sendNotification(dev_token.data.token,modelId,userEmail,chatTitle,body,chatTitle);
      console.log(res.data);
    };
      

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleJigma,
            sendNotificationToApp,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;